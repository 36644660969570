<template>
  <div class="menu">
    <!-- 菜单组件 -->
    <div v-for="(item, index) in menuList" :key="item.title + index">
      <div :class="{ itemDiv: true, finger: true, isClick: item.isClick }" v-if="item.title === '首页'" @click="goPage(item, index)">
        <img :src="item.src" class="img" v-show="!item.isClick" />
        <img :src="item.clickSrc" :class="{ img: true, isClickImg: item.isClick }" v-show="item.isClick" />
        {{ item.title }}
      </div>
      <div v-else>
        <div :class="{ itemDiv: true, finger: true, isClick: item.isClick }" @click="openSubMenu(item, index)">
          <img :src="item.src" :class="{ img: true }" v-show="!item.isClick" />
          <img :src="item.clickSrc" :class="{ img: true, isClickImg: item.isClick }" v-show="item.isClick" />
          {{ item.title }}
          <img src="../assets/images/btm.png" class="rightImg" v-show="!item.isClick" />
          <img src="../assets/images/top.png" class="rightImg" v-show="item.isClick" />
        </div>
        <transition name="slide-fade">
          <div v-if="item.isClick">
            <div v-for="(subItem, subIndex) in item.children" :key="subItem.title + subIndex">
              <div
                :class="{
                  subitemDiv: true,
                  finger: true,
                  isSubClick: subItem.isClick
                }"
                @click="goSubPage(subItem, index, subIndex)"
                v-if="!subItem.children"
              >
                {{ subItem.title }}
              </div>
              <div v-else>
                <div class="thridTitle">{{ subItem.title }}</div>
                <div
                  v-for="(thirdItem, thirdIndex) in subItem.children"
                  :key="thirdItem + thirdIndex"
                  :class="{
                    subitemDiv: true,
                    finger: true,
                    isSubClick: thirdItem.isClick
                  }"
                  @click="goThirdPage(thirdItem, index, subIndex, thirdIndex)"
                >
                  {{ thirdItem.title }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
let Base64 = require('js-base64').Base64
export default {
  data() {
    return {
      menuList: [],
      showIndex: true
    }
  },
  computed: {
    ...mapState(['breadcrumb'])
  },
  created() {
    this.queryMenuList()
    let setMenu = sessionStorage.getItem('=-dwnwnxi0=')
    this.getDefault(this.$route)
    if (setMenu) {
      let list = Base64.decode(sessionStorage.getItem('=-dwnwnxi0='))
      let ayy = list.split(',')
      this.$store.commit('setBreadcrumb', ayy)

      return null
    }
  },
  watch: {
    $route: function(route, t) {
      // this.getDefault(route)
    }
  },
  methods: {
    getDefault(route) {
      let routeName = route.name
      this.menuList.forEach((mItem, mIndex) => {
        mItem.isClick = false
        mItem.children &&
          mItem.children.forEach((i, idx) => {
            i.isClick = false
            if (routeName === i.name) {
              mItem.isClick = true
              i.isClick = true
              let array = []
              array[0] = mItem.title
              array[1] = i.title
              this.$store.commit('setBreadcrumb', array)
            }
          })
      })
    },
    // 打开二级菜单
    openSubMenu(item) {
      // this.$store.commit('setRoutetrPath', arr)
      // 点击的已打开的1级菜单
      if (item.isClick && item.isOpen) {
        item.isClick = false
        item.isOpen = false
        return
      }
      // 点击未打开的1级菜单
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].isClick = false
        if (item.title === this.menuList[i].title) {
          this.$set(item, 'isClick', true)
          this.$set(item, 'isOpen', true)
        }
      }
    },
    // 直接去1级页面
    goPage(item) {
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].isClick = false
        if (item.link === this.menuList[i].link) {
          item.isClick = true
        }
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
        }
      }
      if (this.$route.path === item.path) {
        return
      }
      this.$router.push(item.path)
      let array = []
      array[0] = item.title
      this.$store.commit('setBreadcrumb', array)
    },
    // 直接去2级页面 -- 子级没列表菜单
    goSubPage(item, index, subIndex) {
      if (item.path === this.$route.path) {
        return null
      }
      for (let i = 0; i < this.menuList.length; i++) {
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
          if (item.func_name === this.menuList[i].children[j].func_name) {
            item.isClick = true
          }
          if (this.menuList[i].children[j].children) {
            for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
              this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            }
          }
        }
      }
      this.$router.push(item.path)
      let array = []
      array[0] = this.menuList[index].title
      array[1] = item.title
      this.$store.commit('setBreadcrumb', array)
      // console.log(this.menuList)
    },
    // 查询菜单列表
    async queryMenuList() {
      /* this.menuList = [
        {
          title: '首页',
          src: require('../assets/images/icon_default1.png'),
          clickSrc: require('../assets/images/icon_click1.png'),
          children: [
            {
              title: '首页',
              path: '/main',
              name: 'main'
            }
          ]
        },
        {
          title: '基础管理',
          src: require('../assets/images/icon_default2.png'),
          clickSrc: require('../assets/images/icon_click2.png'),
          children: [
            {
              title: '供应商管理',
              path: '/supplierManage',
              name: 'supplierManage'
            },
            {
              title: '厂家管理',
              path: '/factoryManage',
              name: 'factoryManage'
            },
            {
              title: '产品管理',
              path: '/productManage',
              name: 'productManage'
            }
          ]
        },
        {
          title: '订单管理',
          src: require('../assets/images/icon_default3.png'),
          clickSrc: require('../assets/images/icon_click3.png'),
          children: [
            {
              title: '采购订单',
              path: '/purchaseOrder',
              name: 'purchaseOrder'
            },
            {
              title: '采购退货',
              path: '/purchaseReturns',
              name: 'purchaseReturns'
            }
          ]
        },
        {
          title: '业务管理',
          src: require('../assets/images/icon_default4.png'),
          clickSrc: require('../assets/images/icon_click4.png'),
          children: [
            {
              title: '业务明细',
              path: '/businessDetails',
              name: 'businessDetails'
            }
          ]
        },
        {
          title: '结算协同',
          src: require('../assets/images/icon_default5.png'),
          clickSrc: require('../assets/images/icon_click5.png'),
          children: [
            {
              title: '用量表管理',
              path: '/meterManage',
              name: 'meterManage'
            },
            {
              title: '开票信息',
              path: '/billingInformation',
              name: 'billingInformation'
            }
          ]
        }
      ] */
      let res = await this.$http.get(this.$api.getMenuList)
      this.menuList = res.data
      // console.log(this.menuList)
      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].src = this.menuList[i].src.replace('images', 'static')
        this.menuList[i].clickSrc = this.menuList[i].clickSrc.replace('images', 'static')
        this.$set(this.menuList[i], 'isClick', false)
        this.$set(this.menuList[i], 'title', this.menuList[i].func_name)
        this.$set(this.menuList[i], 'path', this.menuList[i].link)
        if (this.$route.path == this.menuList[i].path) {
          this.$set(this.menuList[i], 'isClick', true)
        }
        if (this.menuList[i].children) {
          for (let j = 0; j < this.menuList[i].children.length; j++) {
            this.$set(this.menuList[i].children[j], 'isClick', false)
            this.$set(this.menuList[i].children[j], 'title', this.menuList[i].children[j].func_name)
            this.$set(this.menuList[i].children[j], 'path', this.menuList[i].children[j].link)

            // 将列表中的权限存入到sessionStorage中
            let navName = this.menuList[i].children[j].func_name
            let rightList = this.menuList[i].children[j].permission
            rightList = JSON.stringify(rightList)
            window.sessionStorage.setItem(navName, rightList)
            // if (this.menuList[i].children[j].children) {
            //   for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
            //     this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            //   }
            // }
            if (this.$route.path == this.menuList[i].children[j].link) {
              this.$set(this.menuList[i], 'isClick', true)
              this.$set(this.menuList[i].children[j], 'isClick', true)
            }
          }
        }
      }
      for (let i = 0; i < this.menuList.length; i++) {
        this.$set(this.menuList[i], 'isClick', false)
        this.$set(this.menuList[i], 'isOpen', false)
        for (let j = 0; j < this.menuList[i].children.length; j++) {
          this.$set(this.menuList[i].children[j], 'isClick', false)
          if(this.menuList[i].children[j].path == this.$route.path) {
            this.$set(this.menuList[i], 'isClick', true)
            this.$set(this.menuList[i], 'isOpen', true)
            this.$set(this.menuList[i].children[j], 'isClick', true)
          }
          if (this.menuList[i].children[j].children) {
            for (let h = 0; h < this.menuList[i].children[j].children.length; h++) {
              this.$set(this.menuList[i].children[j].children[h], 'isClick', false)
            }
          }
        }
      }
      // 页面刷新，导航栏效果显示待处理
      if (this.$route.path === '/main') {
        this.$set(this.menuList[0], 'isClick', true)
        this.$set(this.menuList[0], 'isOpen', true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.menu {
  font-size: 20px;
}
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.itemDiv {
  color: #7f8cd0;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 70px;
}
.subitemDiv {
  padding-left: 90px;
  color: #7f8cd0;
  font-size: 20px;
  height: 70px;
  display: flex;
  align-items: center;
}
.thridTitle {
  margin-left: 60px;
  color: #ccc;
  font-size: 12px;
}
.isClick {
  background: #3f5094;
  color: #fff !important;
  box-sizing: border-box;
  // border-left: 1px solid #426cfc;
}
.isSubClick {
  color: #fff !important;
  background: #426cfc !important;
}
.img {
  margin-right: 14px;
  margin-left: 45px;
  width: 30px;
  height: 30px;
}
.isClickImg {
  margin-left: 39px !important;
}
.rightImg {
  margin-left: 82px;
}
</style>
