<template>
  <div class="main">
    <div class="mainTop">
      <div class="fl">
        <img src="../assets/images/logo.png" class="logo" />
      </div>
      <div class="fr frDiv">
        <!-- <div class="fl show" style="cursor: pointer" @click="goBroadInfo">
          <img src="../assets/images/tips.png" class="tips finger" />
          <span>公告</span>
        </div> -->
        <div class="fl show">
          <Dropdown>
            <a href="javascript:void(0)">
              <img src="../assets/images/head.png" class="head finger" />
            </a>
            <DropdownMenu slot="list">
              <!-- <DropdownItem @click.native="userRole"><Icon type="ios-contact-outline" class="iconClass" />用户信息</DropdownItem> -->
              <!-- <DropdownItem @click.native="systemMsg"><Icon type="ios-mail-outline" class="iconClass" />系统消息</DropdownItem> -->
              <DropdownItem @click.native="changePwd"><Icon type="ios-lock-outline" class="iconClass" />修改密码</DropdownItem>
              <DropdownItem @click.native="loginOut"><Icon type="ios-power-outline" class="iconClass" />退出登录</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <span style="margin-left: 14px">{{ username }}</span>
        </div>
      </div>
    </div>
    <div class="mainBtm">
      <div class="mainLeft clearfix">
        <div class="menu">
          <menu-com></menu-com>
        </div>
        <!-- <div class="btmTips">
          <p>Copyright&copy;2018-{{ nowYear }}</p>
          <p>武汉海星通技术股份有限公司</p>
          <p>All Rights Reserved</p>
        </div> -->
      </div>
      <div class="mainRight">
        <div class="navBox" v-if="navList.length > 0">
          <div v-for="(item, index) in navList" :key="index">{{ item }}<span class="marginItiem" v-if="index < navList.length - 1">/</span></div>
        </div>
        <div v-if="globalLoading" style="height: 100%; width: 100%; position: absolute;">
          <Spin fix>
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
            <div>加载中...</div>
          </Spin>
        </div>
        <keep-alive v-if="keepAliveSH" :exclude="clearCacheList">
          <router-view  class="router_view"></router-view>
        </keep-alive>
      </div>
    </div>
    <div class="mainBot">
      <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2020022570号-1</a>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import menuCom from '@/components/menu.vue'
import { mapState } from 'vuex'
export default {
  components: {
    menuCom
  },
  data() {
    return {
      keepAliveSH: true,
      nowYear: '',
      menuList: [], // 菜单列表
      navList: [],
      username: '',
      clearCacheList: ['purchaseOrderDetails','billingDetails','supplierManage','editProduct','addProduct','manufacturerManage','lookListTable']
    }
  },
  computed: {
    ...mapState(['breadcrumb', 'userInfo', 'globalLoading']),
    router() {
      return this.$route.path
    }
  },
  created() {
    this.nowYear = new Date().getFullYear()
    if (this.$route.path === '/home') {
      this.$router.push('/main')
    }
    if (this.$route.meta.triName) {
      this.navList = [this.$route.meta.title, this.$route.meta.navName, this.$route.meta.triName]
    } else if (this.$route.meta.navName) {
      this.navList = [this.$route.meta.title, this.$route.meta.navName]
    } else {
      this.navList = []
    }
    this.username = window.localStorage.getItem('username')
  },
  methods: {
    // 公告图标跳转
    goBroadInfo() {
      this.$router.push({
        path: '/notice'
      })
    },
    // 个人中心
    personalCenter() {
      this.$Notice.open({ title: '先不管“个人中心”' })
    },
    // 修改密码
    changePwd() {
      this.$router.push('/changePwd')
    },
    // 退出登录
    loginOut() {
      this.$http.post(this.$api.loginOut).then(res => {
        localStorage.removeItem('token')
        localStorage.removeItem('tagList')
        localStorage.removeItem('supplierType')
        this.$Message.success('退出成功')
        this.$router.replace('/')
      })
    }
  },
  watch: {
    $route(to, from) {

      // 某些页面清缓存
      if(sessionStorage.getItem('keepAlive') == 'clearCache'){
        this.keepAliveSH = false
        this.$nextTick(() => {
          this.keepAliveSH = true
        })
      }
      if (to.meta.triName) {
        this.navList = [to.meta.title, to.meta.navName, to.meta.triName]
      } else if (to.meta.navName) {
        this.navList = [to.meta.title, to.meta.navName]
      } else {
        this.navList = []
      }
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .mainTop {
    z-index: 11;
    height: 80px;
    background: rgba(55, 63, 105, 1);
    box-shadow: 0.03rem 4px 15px rgba(39, 37, 37, 0.479);
    padding: 0 30px 0 33px;
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    .logo {
      margin-top: 19px;
      width: 269px;
      height: 48px;
    }
    .frDiv {
      height: 100%;
      .show {
        height: 100%;
        margin-left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .tips {
          width: 24px;
          height: 29px;
          margin-right: 11px;
        }
        .head {
          width: 52px;
          height: 52px;
          border-radius: 100%;
          margin-left: 11px;
        }
      }
    }
  }
  .mainBtm {
    flex: 1;
    display: flex;
    overflow: hidden;
  }
  .mainLeft {
    width: 280px;
    display: flex;
    flex-direction: column;
    .menu {
      flex: 1;
      background: #3f497b;
      overflow-y: auto;
    }

    .btmTips {
      height: 138px;
      background: #3f497b;
      line-height: 20px;
      color: #7d8acd;
      font-size: 14px;
      text-align: center;
      padding-top: 38px;
      border-top: 1px solid #28315d;
    }
  }

  .mainRight {
    padding: 20px 30px 30px 30px;
    flex: 1;
    background: #f0f0f0;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    .router_view {
      flex: 1;
      background: #fff;
      border-radius: 8px;
      overflow-y: auto;
    }
    .navBox {
      display: flex;
      margin-bottom: 17px;
      div {
        font-size: 16px;
        color: rgba(153, 153, 153, 1);
      }
      .marginItiem {
        margin: 0 10px;
      }
      div:last-child {
        color: #333333;
      }
    }
  }
}
.mainBot {
  a {
    color: #333;
  }
  position: absolute;
  bottom: 0;
  left: 900px;
  text-align: center;
}
</style>
